import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "22",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#a)" }, [
      _createElementVNode("path", {
        fill: "#005C97",
        "fill-rule": "evenodd",
        d: "m10.5 12.857 4.64 4.64a1.313 1.313 0 1 0 1.857-1.856L12.356 11l4.64-4.64a1.313 1.313 0 0 0-1.856-1.856l-4.64 4.64-4.64-4.64A1.312 1.312 0 1 0 4.005 6.36L8.646 11l-4.641 4.64a1.313 1.313 0 1 0 1.856 1.857l4.64-4.641",
        "clip-rule": "evenodd"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 .5h21v21H0z"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }